<template>
  <CreateAccountComponent />
</template>
<script>
import CreateAccountComponent from "@/esf_utrecht_bankxl/core/components/auth/CreateAccountComponent.vue";
export default {
  name: "CreateAccount",
  components: {
    CreateAccountComponent,
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("create_new_account") },
        { name: "keywords", content: this.$t("create_new_account") },
        { name: "description", content: this.$t("create_new_account") },
      ],
      title: this.$t("create_new_account"),
    };
  },
};
</script>
