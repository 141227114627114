<template>
  <b-container>
    <div class="create-account mx-auto login-form-box">
      <b-form @submit.prevent="formSubmit" novalidate>
        <b-row align-h="center">
          <b-col cols="12">
            <div class="create-account-title">
              <h4 class="create-account-title text-center">
                {{ $t("create_new_account") }}
              </h4>
            </div>
            <div class="create-account-form">
              <div class="form-item-wrap">
                <b-form-group
                  id="email-group"
                  key="email-group"
                  :label="$t('form_email')"
                  label-for="email"
                  :aria-required="true"
                >
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    type="text"
                    :state="form.emailState"
                    aria-describedby="email-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="email-feedback">
                    {{ form.emailError }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <h3 style="border-bottom: 1px solid #525252" class="pb-2">
                {{ $t("shipping_address") }}
              </h3>

              <div class="form-item-wrap">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="isCompany"
                  name="checkbox-1"
                  class="pl-30"
                  >{{ $t("form_are_you_a_company") }}</b-form-checkbox
                >
                <div v-if="isCompany" class="short-form">
                  <div class="form-group-wrap">
                    <b-form-group
                      class="account-inputs"
                      id="company-group"
                      :label="$t('form_company_name')"
                      label-class="required"
                      label-for="company"
                    >
                      <b-form-input
                        id="company"
                        v-model="form.company"
                        type="text"
                        v-on:blur="validateCompany()"
                        :state="form.companyState"
                        aria-describedby="company-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="company-feedback">
                        {{ form.companyError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="form-group-wrap">
                    <b-form-group
                      class="account-inputs"
                      id="coc_number-group"
                      :label="$t('form_coc_number')"
                      label-class="required"
                      label-for="coc_number"
                    >
                      <b-form-input
                        id="coc_number"
                        v-model="form.coc_number"
                        type="text"
                        v-on:blur="validateCOCNumber()"
                        :state="form.coc_numberState"
                        aria-describedby="coc_number-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="coc_number-feedback">
                        {{ form.coc_numberError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="form-group-wrap">
                    <b-form-group
                      class="account-inputs"
                      id="taxvat-group"
                      :label="$t('form_vat_number')"
                      label-class="required"
                      label-for="taxvat"
                    >
                      <b-form-input
                        id="taxvat"
                        v-model="form.taxvat"
                        type="text"
                        :state="form.taxvatState"
                        v-on:blur="validateVAT"
                        aria-describedby="taxvat-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="taxvat-feedback">
                        {{ form.taxvatError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="first_name-group"
                    :label="$t('form_first_name')"
                    label-class="required"
                    label-for="first_name"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="form.firstname"
                      type="text"
                      v-on:blur="validateFirstname()"
                      :state="form.firstnameState"
                      aria-describedby="firstname-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="firstname-feedback">
                      {{ form.firstnameError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="last_name-group"
                    :label="$t('form_last_name')"
                    label-for="last_name"
                    label-class="required"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="form.lastname"
                      type="text"
                      v-on:blur="validateLastname()"
                      :state="form.lastnameState"
                      aria-describedby="lastname-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="lastname-feedback">
                      {{ form.lastnameError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="country-group"
                    :label="$t('form_country')"
                    label-class="required"
                    label-for="country"
                  >
                    <b-form-select
                      id="country"
                      v-model="form.address.country_code"
                      :options="countries"
                      class="select"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="form-group-wrap">
                  <b-form-group
                    v-if="!showFullAddress"
                    class="account-inputs"
                    id="postcode-group"
                    :label="$t('form_postal_code')"
                    label-class="required"
                    label-for="postcode"
                  >
                    <b-form-input
                      id="postcode"
                      v-model="form.address.postcode"
                      type="text"
                      :state="form.address.postcodeState"
                      aria-describedby="address.postcode-feedback"
                      v-on:blur="validatePostcode()"
                      :placeholder="postcodePlaceholder"
                      :length="postcodeLength"
                    ></b-form-input>
                    <b-form-invalid-feedback id="address.postcode-feedback">
                      {{ form.address.postcodeError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    v-if="showFullAddress"
                    class="account-inputs"
                    id="street-group"
                    :label="$t('form_street') + '*'"
                    label-for="street"
                  >
                    <b-form-input
                      id="street"
                      v-model="form.address.streetDisplay"
                      type="text"
                      v-on:blur="validateStreet"
                      :state="form.address.streetDisplayState"
                      aria-describedby="address.streetDisplay-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="address.streetDisplay-feedback"
                    >
                      {{ form.address.streetDisplayError }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-row>
                    <b-col cols="6" lg="6" md="12" sm="6">
                      <div class="form-group-wrap">
                        <b-form-group
                          class="account-inputs"
                          id="house_number-group"
                          :label="$t('form_house_number')"
                          label-class="required"
                          label-for="house_number"
                        >
                          <b-form-input
                            id="house_number"
                            v-model="form.address.house_number"
                            type="text"
                            v-on:blur="validateHouseNumber()"
                            :state="form.address.house_numberState"
                            aria-describedby="address.house_number-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="address.house_number-feedback"
                          >
                            {{ form.address.house_numberError }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col cols="6" lg="6" md="12" sm="6">
                      <div class="form-group-wrap">
                        <b-form-group
                          class="account-inputs"
                          id="addition-group"
                          :label="$t('form_addition')"
                          label-for="addition"
                        >
                          <b-form-input
                            id="addition"
                            v-model="form.address.addition"
                            type="text"
                            v-on:blur="validateHouseNumber()"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>

                  <div class="form-group-wrap">
                    <b-form-group
                      v-if="showFullAddress"
                      class="account-inputs"
                      id="postcode-group1"
                      :label="$t('form_postal_code') + '*'"
                      label-for="postcode1"
                    >
                      <b-form-input
                        id="postcode1"
                        v-model="form.address.postcode"
                        type="text"
                        v-on:blur="validatePostcode"
                        :state="form.address.postcodeState"
                        aria-describedby="address.postcode1-feedback"
                        :placeholder="postcodePlaceholder"
                        :length="postcodeLength"
                      ></b-form-input>
                      <b-form-invalid-feedback id="address.postcode1-feedback">
                        {{ form.address.postcodeError }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                      v-if="showFullAddress"
                      class="account-inputs"
                      id="city-group"
                      :label="$t('form_city') + '*'"
                      label-for="city"
                    >
                      <b-form-input
                        id="city"
                        v-model="form.address.city"
                        type="text"
                        v-on:blur="validateCity"
                        :state="form.address.cityState"
                        aria-describedby="address.city-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="address.city-feedback">
                        {{ form.address.cityError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group
                      class="account-inputs"
                      id="add-group"
                      label=" "
                      label-for="add"
                    >
                      <b-form-input
                        v-model="foundAddress"
                        id="add"
                        type="text"
                        :readonly="readonly"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      v-if="
                        foundAddresses.length > 0 && showFullAddress == false
                      "
                      class="account-inputs"
                      id="addm-group"
                      label=" "
                      label-for="addm"
                    >
                      <div id="addm">
                        <b-form-radio
                          style="width: 300px"
                          class="account-radios"
                          v-model="selectedAddress"
                          v-for="(item, index) in foundAddresses"
                          :key="`nl-${index}`"
                          name="some-radios"
                          :value="index"
                          >{{ item.text }}</b-form-radio
                        >
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="account-inputs"
                      id="manual-group"
                      label=" "
                      label-for="manual"
                    >
                      <b-form-checkbox
                        v-if="showManual"
                        id="manual"
                        v-model="showFullAddress"
                        name="manual"
                        class="pl-30"
                        >{{
                          $t("form_fillout_address_manual")
                        }}</b-form-checkbox
                      >
                    </b-form-group>
                    <b-form-group
                      class="account-inputs"
                      id="telephone-group"
                      :label="$t('form_phone')"
                      label-class="required"
                      label-for="telephone"
                    >
                      <b-form-input
                        id="telephone"
                        v-model="form.address.telephone"
                        type="text"
                        v-on:blur="validatePhone()"
                        :state="form.address.telephoneState"
                        aria-describedby="address.telephone-feedback"
                        :placeholder="phonenumberPlaceholder"
                      ></b-form-input>
                      <b-form-invalid-feedback id="address.telephone-feedback">
                        {{ form.address.telephoneError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <b-row>
                <b-col cols="6" sm="6">
                  <div class="form-item-wrap">
                    <b-form-group
                      class="password-grp"
                      id="password-group"
                      key="password-group"
                      :label="$t('form_password')"
                      label-for="password"
                      :aria-required="true"
                    >
                      <!-- <b-form-input
                        id="password"
                        v-model="form.password"
                        type="password"
                        :state="form.passwordState"
                        aria-describedby="password-feedback"
                      ></b-form-input>
                      <client-only>
                        <password-meter :password="form.password" />
                      </client-only> -->
                      <div class="form-group password-watch">
                        <b-form-input
                          v-model="form.password"
                          :type="form.passwordFieldType"
                          :placeholder="$t('password')"
                          id="text-password"
                          :state="form.passwordState"
                          aria-describedby="password-feedback"
                          class="form-item"
                        >
                        </b-form-input>
                        <span class="show-password">
                          <b-link class="span-txt" @click="toggleShowPass">
                            {{
                              form.passwordFieldType === "password"
                                ? $t("show")
                                : $t("hide")
                            }}
                          </b-link>
                        </span>
                      </div>

                      <b-form-invalid-feedback id="password-feedback">
                        {{ form.passwordError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col cols="6" sm="6">
                  <div class="form-item-wrap">
                    <b-form-group
                      id="password_confirm-group"
                      key="password_confirm-group"
                      :label="$t('form_password_confirmation')"
                      label-for="password_confirm"
                      :aria-required="true"
                    >
                      <!-- <b-form-input
                        id="password_confirm"
                        v-model="form.password_confirm"
                        type="password"
                        :state="form.password_confirmState"
                        aria-describedby="password_confirm-feedback"
                      ></b-form-input> -->

                      <div class="form-group password-watch">
                        <b-form-input
                          v-model="form.password_confirm"
                          :type="form.confirmPasswordFieldType"
                          id="password_confirm"
                          :state="form.password_confirmState"
                          aria-describedby="password_confirm-feedback"
                          class="form-item"
                        >
                        </b-form-input>
                        <span class="show-password">
                          <b-link class="span-txt" @click="confirmPasswordToggleShowPass">
                            {{
                              form.confirmPasswordFieldType === "password"
                                ? $t("show")
                                : $t("hide")
                            }}
                          </b-link>
                        </span>
                      </div>

                      <b-form-invalid-feedback id="password_confirm-feedback">
                        {{ form.password_confirmError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </div>

            <div class="mb-4">
              <b-form-checkbox
                label-for="checkbox2"
                v-model="form.address.default_billing"
                >{{ $t("form_address_also_invoice_address") }}</b-form-checkbox
              >

              <div
                class="form-item-wrap"
                v-if="form.address.default_billing == false"
              >
                <h3 style="border-bottom: 1px solid #525252" class="py-2">
                  {{ $t("invoice_address") }}
                </h3>
                <div class="form-item">
                  <b-form-checkbox
                    id="checkbox-99"
                    v-model="isInvoiceCompany"
                    name="checkbox-99"
                    >{{ $t("form_are_you_a_company") }}</b-form-checkbox
                  >
                </div>

                <!-- Is Invoice Company -->
                <div v-if="isInvoiceCompany" class="short-form">
                  <div class="order-title">
                    <span><font-awesome-icon icon="home" /></span>
                    {{ $t("invoice_address") }}
                  </div>
                  <div class="form-group-wrap">
                    <b-form-group
                      class="account-inputs"
                      id="company-group"
                      :label="$t('form_company_name')"
                      label-class="required"
                      label-for="company"
                    >
                      <b-form-input
                        id="company"
                        v-model="form.invoice.company"
                        type="text"
                        v-on:blur="validateInvoiceCompany()"
                        :state="form.invoice.companyState"
                        aria-describedby="company-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="company-feedback">
                        {{ form.invoice.companyError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="form-group-wrap">
                    <b-form-group
                      class="account-inputs"
                      id="coc_number-group"
                      :label="$t('form_coc_number')"
                      label-class="required"
                      label-for="coc_number"
                    >
                      <b-form-input
                        id="coc_number"
                        v-model="form.invoice.coc_number"
                        type="text"
                        v-on:blur="validateInvoiceCOCNumber()"
                        :state="form.invoice.coc_numberState"
                        aria-describedby="coc_number-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="coc_number-feedback">
                        {{ form.invoice.coc_numberError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="form-group-wrap">
                    <b-form-group
                      class="account-inputs"
                      id="taxvat-group"
                      :label="$t('form_vat_number')"
                      label-class="required"
                      label-for="taxvat"
                    >
                      <b-form-input
                        id="taxvat"
                        v-model="form.invoice.taxvat"
                        type="text"
                        :state="form.invoice.taxvatState"
                        aria-describedby="taxvat-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="taxvat-feedback">
                        {{ form.invoice.taxvatError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>

                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="first_name-group"
                    :label="$t('form_first_name')"
                    label-class="required"
                    label-for="first_name"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="form.invoice.firstname"
                      type="text"
                      v-on:blur="validateInvoiceFirstname()"
                      :state="form.invoice.firstnameState"
                      aria-describedby="firstname-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="firstname-feedback">
                      {{ form.invoice.firstnameError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="last_name-group"
                    :label="$t('form_last_name')"
                    label-class="required"
                    label-for="last_name"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="form.invoice.lastname"
                      type="text"
                      v-on:blur="validateInvoiceLastname()"
                      :state="form.invoice.lastnameState"
                      aria-describedby="lastname-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="lastname-feedback">
                      {{ form.invoice.lastnameError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="country-group"
                    :label="$t('form_country')"
                    label-class="required"
                    label-for="country"
                  >
                    <b-form-select
                      id="country"
                      v-model="form.invoice.address.country_code"
                      :options="countries"
                      class="select"
                    ></b-form-select>
                  </b-form-group>
                </div>

                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="postcode-group"
                    :label="$t('form_postal_code')"
                    label-class="required"
                    label-for="postcode"
                  >
                    <b-form-input
                      id="postcode"
                      v-model="form.invoice.address.postcode"
                      type="text"
                      :state="form.invoice.address.postcodeState"
                      aria-describedby="address.invoice.postcode-feedback"
                      v-on:blur="validateInvoicePostcode()"
                      :placeholder="postcodePlaceholder"
                      :length="postcodeLength"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="address.invoice.postcode-feedback"
                    >
                      {{ form.invoice.address.postcodeError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <b-row>
                  <b-col cols="6" lg="6" md="12" sm="6">
                    <div class="form-group-wrap">
                      <b-form-group
                        class="account-inputs"
                        id="house_number-group"
                        :label="$t('form_house_number')"
                        label-class="required"
                        label-for="house_number"
                      >
                        <b-form-input
                          id="house_number"
                          v-model="form.invoice.address.house_number"
                          type="text"
                          v-on:blur="validateInvoiceHouseNumber()"
                          :state="form.invoice.address.house_numberState"
                          aria-describedby="address.invoice.house_number-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="address.invoice.house_number-feedback"
                        >
                          {{ form.invoice.address.house_numberError }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="6" md="12" sm="6"
                    ><div class="form-group-wrap">
                      <b-form-group
                        class="account-inputs"
                        id="addition-group"
                        :label="$t('form_addition')"
                        label-for="addition"
                      >
                        <b-form-input
                          id="addition"
                          v-model="form.invoice.address.addition"
                          type="text"
                        ></b-form-input>
                      </b-form-group></div
                  ></b-col>
                </b-row>
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="street-group"
                    :label="$t('form_street')"
                    label-class="required"
                    label-for="street"
                  >
                    <b-form-input
                      id="street"
                      v-model="form.invoice.address.streetDisplay"
                      type="text"
                      v-on:blur="validateInvoiceStreet()"
                      :state="form.invoice.address.streetDisplayState"
                      aria-describedby="address.invoice.streetDisplay-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="address.invoice.streetDisplay-feedback"
                    >
                      {{ form.invoice.address.streetDisplayError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="city-group"
                    :label="$t('form_city')"
                    label-class="required"
                    label-for="city"
                  >
                    <b-form-input
                      id="city"
                      v-model="form.invoice.address.city"
                      type="text"
                      v-on:blur="validateInvoiceCity()"
                      :state="form.invoice.address.cityState"
                      aria-describedby="form.invoice.address.city-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="form.invoice.address.city-feedback"
                    >
                      {{ form.invoice.address.cityError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="telephone-group"
                    :label="$t('form_phone')"
                    label-class="required"
                    label-for="telephone"
                  >
                    <b-form-input
                      id="telephone"
                      v-model="form.invoice.address.telephone"
                      type="text"
                      v-on:blur="validateInvoicePhone()"
                      :state="form.invoice.address.telephoneState"
                      aria-describedby="form.invoice.address.telephone-feedback"
                      :placeholder="phonenumberPlaceholder"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="form.invoice.address.telephone-feedback"
                    >
                      {{ form.invoice.address.telephoneError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>

              <b-form-checkbox
                label-for="checkbox3"
                v-model="form.is_subscribed"
                >{{ $t("form_newsletter_subscription") }}</b-form-checkbox
              >
            </div>
            <div class="required-field">* {{ $t("required_field") }}</div>
            <div class="create-account-button text-center">
              <b-button type="submit" class="green-btn">{{
                $t("create_new_account")
              }}</b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import {
  validatePostcode,
  validateVatNumber,
  checkEmailExist,
} from "@storefront/core/data-resolver/user";
import config from "@config";
// import ClientOnly from "vue-client-only";
// import { isServer } from "@storefront/core/helpers";

export default {
  name: "CreateAccountComponent",
  components: {
    // passwordMeter: () =>
    //   !isServer ? import("vue-simple-password-meter") : null,
    // ClientOnly,
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("create_new_account") },
        { name: "keywords", content: this.$t("create_new_account") },
        { name: "description", content: this.$t("create_new_account") },
      ],
      title: this.$t("create_new_account"),
    };
  },
  mounted() {
    if (typeof config.customers.defaultCountry != "undefined") {
      this.form.address.country_code = config.customers.defaultCountry;
      this.form.invoice.address.country_code = config.customers.defaultCountry;
    }
    if (config.postcode_validation.enabled == false) {
      this.manual = true;
      this.showManual = false;
    }
    if (config.vat_validation.enabled == false) {
      this.showVatCheck = false;
    }
  },

  data() {
    return {
      useremail: "",
      isCompany: false,
      thanksActive: false,
      showFullAddress: true,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      selectedAddress: null,
      isValidVatNumber: false,
      showVatMessage: "",
      showVatCheck: false,
      isInvoiceCompany: false,
      isValidInvoiceVatNumber: false,
      showFullInvoiceAddress: false,
      invoiceAddressTimer: null,
      foundInvoiceAddresses: [],
      foundInvoiceAddress: "",
      selectedInvoiceAddress: null,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regExNlPhone:
        /^((\+|00)31[1-9][0-9]{8}||0[1-9][0-9][- ]?[1-9][0-9]{6}||0[1-9][0-9]{2}[- ][1-9][0-9]{5}||06[- ]?[1-9][0-9]{7})$/,
      regExBePhone:
        /^(((\+|00)32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}||((\+|00)32\s?|0)4(60|[789]\d)(\s?\d{2}){3})$/,
      regExDePhone:
        /^((\+|00)49[1-9][0-9]{9}||0[1-9][0-9]{9}||015[1-9][0-9]{8}||01(6|7)[1-9][0-9]{7,8}||(\+|00)491(6|7)[1-9][0-9]{7,8})$/,
      regExGenPhone: /^(\+|00)[1-9][0-9]+$/,
      form: {
        email: "",
        emailState: null,
        emailError: "",
        emailTimer: null,
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
        passwordFieldType: "password",
        confirmPasswordFieldType: "password",
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        coc_number: "",
        coc_numberState: null,
        coc_numberError: "",
        coc_numberTimer: null,
        taxvat: "",
        taxvatState: null,
        taxvatError: "",
        taxvatTimer: null,
        address: {
          country_code: "",
          postcode: "",
          postcodeState: null,
          postcodeError: "",
          postcodeTimer: null,

          street: "",
          streetDisplay: "",
          streetDisplayState: null,
          streetDisplayError: "",
          streetDisplayTimer: null,

          house_number: "",
          house_numberState: null,
          house_numberError: "",
          house_numberTimer: null,

          addition: "",
          city: "",
          cityState: null,
          cityError: "",
          cityTimer: null,

          telephone: "",
          telephoneState: null,
          telephoneError: "",
          telephoneTimer: null,
          default_billing: true,
          default_shipping: true,
        },
        invoice: {
          isCompany: true,

          company: "",
          companyState: null,
          companyError: "",
          companyTimer: null,
          firstname: "",
          firstnameState: null,
          firstnameError: "",
          firstnameTimer: null,
          lastname: "",
          lastnameState: null,
          lastnameError: "",
          lastnameTimer: null,
          coc_number: "",
          coc_numberState: null,
          coc_numberError: "",
          coc_numberTimer: null,
          taxvat: "",
          taxvatState: null,
          taxvatError: "",
          taxvatTimer: null,
          address: {
            country_code: "",
            postcode: "",
            postcodeState: null,
            postcodeError: "",
            postcodeTimer: null,

            street: "",
            streetDisplay: "",
            streetDisplayState: null,
            streetDisplayError: "",
            streetDisplayTimer: null,

            house_number: "",
            house_numberState: null,
            house_numberError: "",
            house_numberTimer: null,

            addition: "",
            city: "",
            cityState: null,
            cityError: "",
            cityTimer: null,

            telephone: "",
            telephoneState: null,
            telephoneError: "",
            telephoneTimer: null,
            default_billing: true,
            default_shipping: true,
          },
        },
        is_subscribed: false,
        checkbox2: false,
        manual: false,
      },
      readonly: "",
      postcodePlaceholder: "1111AA",
      phonenumberPlaceholder: "0612345678",
      postcodeLength: 6,
    };
  },
  created() {
    this.form.email = this.$route.params.useremail;
  },
  computed: {
    countries() {
      return this.$store.getters["user/getCountries"];
    },
    country() {
      return this.form.address.country_code;
    },
    postcodeHouseNumber() {
      return `${this.form.address.postcode}|${this.form.address.house_number}|${this.form.address.addition}`;
    },
    vatNumber() {
      return this.form.taxvat;
    },
    Email() {
      return this.form.email;
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    invoiceAddress() {
      return `${this.form.invoice.company}|${this.form.invoice.taxvat}|${this.form.invoice.firstname}|${this.form.invoice.lastname}|${this.form.invoice.address.country_code}|${this.form.invoice.address.city}|${this.form.invoice.address.streetDisplay}|${this.form.invoice.address.house_number}|${this.form.invoice.address.addition}|${this.form.invoice.address.telephone}||${this.form.invoice.address.city}`;
    },
    invoicePostcodeHouseNumber() {
      return `${this.form.invoice.address.postcode}|${this.form.invoice.address.house_number}|${this.form.invoice.address.addition}`;
    },
    invoiceCountry() {
      return this.form.invoice.address.country_code;
    },
    invoiceVatNumber() {
      return this.form.invoice.taxvat;
    },
  },
  watch: {
    isLoggedIn() {
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        this.$router.push({ name: "account" });
      }
    },

    Email() {
      if (this.form.emailTimer !== null) {
        clearTimeout(this.form.emailTimer);
      }
      this.form.emailTimer = setTimeout(
        function (scope) {
          scope.validateEmail();
        },
        1000,
        this
      );
    },
    Password() {
      this.validatePassword();
    },
    PasswordConfirm() {
      this.validateConfirmationPassword();
    },
    country() {
      if (this.form.address.postcode.length > 0) {
        this.validatePostcode();
      }
      if (this.isCompany == true) {
        if (this.form.taxvat != "") {
          this.validateVAT();
        }
      }
      switch (this.form.address.country_code) {
        case "NL":
          this.postcodePlaceholder = "1111AA";
          this.phonenumberPlaceholder = "0612345678";
          this.postcodeLength = 6;
          break;
        case "BE":
          this.postcodePlaceholder = "1111";
          this.phonenumberPlaceholder = "+3212345678";
          this.postcodeLength = 4;
          break;
        case "DE":
          this.postcodePlaceholder = "11111";
          this.phonenumberPlaceholder = "+491234567890";
          this.postcodeLength = 5;
          break;
        default:
          this.postcodePlaceholder = "1111AA";
          this.phonenumberPlaceholder = "0612345678";
          this.postcodeLength = 6;
      }
    },
    vatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.taxvatTimer !== null) {
          clearTimeout(this.form.taxvatTimer);
        }
        this.form.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateVAT();
          },
          1000,
          this
        );
      }
    },
    postcodeHouseNumber(newVal) {
      if (this.typeTimer !== null) {
        clearTimeout(this.typeTimer);
      }
      if (this.foundAddress != "") {
        this.foundAddress = "";
      }
      if (this.foundAddresses.length > 0) {
        this.foundAddresses = [];
      }
      //const [oldPostcode, oldHouseNumber] = oldVal.split("|");
      const [newPostcode, newHouseNumber] = newVal.split("|");

      if (newPostcode != "" && newHouseNumber != "") {
        this.typeTimer = setTimeout(
          function (scope) {
            scope.validatePostcodeHouseNumber();
          },
          2000,
          this
        );
      }
    },
    selectedAddress(newVal) {
      this.form.address.streetDisplay = this.foundAddresses[newVal].street;
      if (this.foundAddresses[newVal].letter != null) {
        this.form.address.addition = this.foundAddresses[newVal].letter;
      } else {
        this.form.address.addition = "";
      }
      this.form.address.city = this.foundAddresses[newVal].city;
      this.validatePostcode();
    },
    invoiceAddress() {
      if (this.form.invoiceAddressTimer !== null) {
        clearTimeout(this.form.invoiceAddressTimer);
      }
      this.form.invoiceAddressTimer = setTimeout(
        function (scope) {
          scope.validateInvoiceAddress();
        },
        1000,
        this
      );
    },
    invoiceCountry() {
      if (this.form.invoice.address.postcode.length > 0) {
        this.validateInvoicePostcode();
      }
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.taxvat != "") {
          this.validateInvoiceVAT();
        }
      }
    },
    invoiceVatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.invoice.taxvatTimer !== null) {
          clearTimeout(this.form.invoice.taxvatTimer);
        }
        this.form.invoice.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateInvoiceVAT();
          },
          1000,
          this
        );
      }
    },
    invoicePostcodeHouseNumber(newVal) {
      if (this.typeTimer !== null) {
        clearTimeout(this.typeTimer);
      }
      if (this.foundInvoiceAddress != "") {
        this.foundInvoiceAddress = "";
      }
      if (this.foundInvoiceAddresses.length > 0) {
        this.foundInvoiceAddresses = [];
      }
      //const [oldPostcode, oldHouseNumber] = oldVal.split("|");
      const [newPostcode, newHouseNumber] = newVal.split("|");
      if (newPostcode != "" && newHouseNumber != "") {
        this.typeTimer = setTimeout(
          function (scope) {
            scope.validateInvoicePostcodeHouseNumber();
          },
          2000,
          this
        );
      }
    },
    selectedInvoiceAddress(newVal) {
      this.form.invoice.address.streetDisplay =
        this.foundInvoiceAddresses[newVal].street;
      if (this.foundInvoiceAddresses[newVal].letter != null) {
        this.form.invoice.address.addition =
          this.foundInvoiceAddresses[newVal].letter;
      } else {
        this.form.invoice.address.addition = "";
      }
      this.form.invoice.address.city = this.foundAddresses[newVal].city;
      this.validateInvoicePostcode();
    },
    showFullAddress(newVal) {
      this.readonly = newVal;
    },
  },
  methods: {
    async validateEmail() {
      if (this.form.email) {
        if (this.form.email.length == 0) {
          this.form.emailState = false;
          this.form.emailError = this.$t("email_is_required_field");
          return false;
        } else if (!this.emailReg.test(this.form.email)) {
          this.form.emailState = false;
          this.form.emailError = this.$t("email_is_invalid_field");
          return false;
        } else if (!(await checkEmailExist(this.form.email))) {
          this.form.emailState = false;
          this.form.emailError = this.$t("email_already_exists");
          return false;
        }
        this.form.emailState = true;
        return true;
      } else {
        return false;
      }
    },
    async formSubmit() {
      //TODO Validation
      let formValid = true;
      if (this.form.emailState != true) {
        this.validateEmail();
        if (this.form.emailState != true) {
          formValid = false;
        }
      }
      if (this.form.passwordState != true) {
        this.validatePassword();
        if (this.form.passwordState != true) {
          formValid = false;
        }
      }
      if (this.form.password_confirmState != true) {
        this.validateConfirmationPassword();
        if (this.form.password_confirmState != true) {
          formValid = false;
        }
      }

      if (this.isCompany == true) {
        if (this.form.companyState != true) {
          this.validateCompany();
          if (this.form.companyState != true) {
            formValid = false;
          }
        }
        /*        if (this.form.coc_numberState != true) {
          this.validateCOCNumber();
          if (this.coc_numberState != true) {
            formValid = false;
          }
        }
        if (this.form.taxvatState != true) {
          this.validateVAT();
          if (this.taxvatState != true) {
            formValid = false;
          }
        }*/
      }
      if (this.form.firstnameState != true) {
        this.validateFirstname();
        if (this.firstnameState != true) {
          formValid = false;
        }
      }
      if (this.form.lastnameState != true) {
        this.validateLastname();
        if (this.lastnameState != true) {
          formValid = false;
        }
      }
      if (this.form.address.postcodeState != true) {
        this.validatePostcode();
        if (this.form.address.postcodeState != true) {
          formValid = false;
        }
      }
      if (this.form.address.house_numberState != true) {
        this.validateHouseNumber();
        if (this.form.address.house_numberState != true) {
          formValid = false;
        }
      }
      if (this.form.address.streetDisplayState != true) {
        this.validateStreet();
        if (this.form.address.streetDisplayState != true) {
          formValid = false;
          this.showFullAddress = true;
        }
      }
      if (this.form.address.telephoneState != true) {
        this.validatePhone();
        if (this.form.address.telephoneState != true) {
          formValid = false;
        }
      }

      if (this.form.address.cityState != true) {
        this.validateCity();
        if (this.form.address.cityState != true) {
          formValid = false;
        }
      }

      if (formValid == true) {
        this.form.address.street = this.form.address.streetDisplay;
        if (this.form.address.house_number != "") {
          this.form.address.street =
            this.form.address.street + " " + this.form.address.house_number;
        }
        if (this.form.address.addition != "") {
          this.form.address.street =
            this.form.address.street + "" + this.form.address.addition;
        }
        if (this.isCompany != true) {
          this.form.company = "";
          this.form.taxvat = "";
        }

        // Deleted coc_number field
        // const formData = { ...this.form };
        // delete formData.coc_number;

        const retval = await this.$store.dispatch("user/createAccount", {
          account: this.form,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: "account_create",
            text: this.$t("account_created"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.$router.push({ name: "account" });
        }
      }

      //this.thanksActive = true;
    },
    validatePassword() {
      if (this.form.password.length < 8) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[a-z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (
        this.form.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[A-Z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/\d/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else {
        this.form.passwordState = true;
      }
    },
    validateConfirmationPassword() {
      if (
        this.form.password_confirm &&
        this.form.password != this.form.password_confirm
      ) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_not_equal");
      } else if (this.form.password_confirm == "") {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_required");
      } else {
        this.form.password_confirmState = true;
      }
    },
    async validateVAT() {
      if (this.isCompany == true) {
        if (this.form.taxvat != "") {
          if (
            this.form.taxvat.substring(0, 2).toUpperCase() ===
            this.form.address.country_code
          ) {
            const retval = await validateVatNumber(this.form.taxvat);
            if (retval.valid == "true") {
              this.isValidVatNumber = true;
              this.form.taxvatState = true;
            } else {
              this.isValidVatNumber = false;
              this.form.taxvatState = false;
              this.form.taxvatError = this.$t("taxvat_invalid");

              this.showVatMessage = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidVatNumber = false;
            this.form.taxvatState = false;
            this.form.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      } else {
        this.form.taxvatState = null;
        this.form.taxvatError = "";
      }
    },
    validateCompany() {
      if (this.isCompany == true) {
        if (this.form.company == "") {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
        }
      } else {
        this.form.companyState = null;
      }
    },
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    validateCOCNumber() {
      if (this.isCompany == true) {
        // if (this.form.coc_number == "") {
        //   this.form.coc_numberState = false;
        //   this.form.coc_numberError = this.$t("coc_number_required");
        // } else {
        //   this.form.coc_numberState = true;
        // }
      } else {
        this.form.coc_numberState = null;
      }
    },
    validateStreet() {
      if (this.form.address.streetDisplay == "") {
        this.form.address.streetDisplayState = false;
        this.form.address.streetDisplayError = this.$t("street_required");
      } else {
        this.form.address.streetDisplayState = true;
      }
    },
    validateHouseNumber() {
      if (this.form.address.house_number == "") {
        this.form.address.house_numberState = false;
        this.form.address.house_numberError = this.$t("house_number_required");
      } else {
        this.form.address.house_numberState = true;
      }
    },
    validateCity() {
      if (this.form.address.city == "") {
        this.form.address.cityState = false;
        this.form.address.cityError = this.$t("city_required");
      } else {
        this.form.address.cityState = true;
      }
    },
    validatePhone() {
      if (this.form.address.telephone == "") {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else if (
        this.form.address.telephone.substring(0, 3) == "+31" ||
        (this.form.address.country_code == "NL" &&
          this.form.address.telephone.substring(0, 1) !== "+")
      ) {
        if (this.regExNlPhone.test(this.form.address.telephone) == null) {
          this.form.address.telephoneState = false;
          this.form.address.telephoneError = this.$t("phone_nl_regex_test");
        } else {
          this.form.address.telephoneState = true;
        }
      } else if (
        this.form.address.telephone.substring(0, 3) == "+32" ||
        (this.form.address.country_code == "BE" &&
          this.form.address.telephone.substring(0, 1) !== "+")
      ) {
        if (!this.regExBePhone.test(this.form.address.telephone) == null) {
          this.form.address.telephoneState = false;
          this.form.address.telephoneError = this.$t("phone_be_regex_test");
        } else {
          this.form.address.telephoneState = true;
        }
      } else if (
        this.form.address.telephone.substring(0, 3) == "+49" ||
        (this.form.address.country_code == "DE" &&
          this.form.address.telephone.substring(0, 1) !== "+")
      ) {
        if (!this.regExDePhone.test(this.form.address.telephone) == null) {
          this.form.address.telephoneState = false;
          this.form.address.telephoneError = this.$t("phone_de_regex_test");
        } else {
          this.form.address.telephoneState = true;
        }
      } else if (this.regExGenPhone.test(this.form.address.telephone) == null) {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.address.telephoneState = true;
      }
    },
    async validatePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.address.postcode,
          this.form.address.house_number
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            this.form.address.addition = list[0].letter;
            address = address + list[0].letter;
          } else {
            this.form.address.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.address.city = list[0].city;
          this.form.address.cityState = true;
          this.foundAddress = address;
          this.form.address.streetDisplayState = true;
          this.validatePostcode();
        } else if (list.length == 0) {
          this.foundAddress = this.$t("postcode_not_found");
        } else {
          this.foundAddresses = [];
          list.forEach(function (item) {
            if (this.form.address.addition == "") {
              let address = item.street + " " + item.number;
              if (item.letter != null) {
                address = address + item.letter;
              }
              address = address + ", " + item.postcode + " " + item.city;
              item.text = address;
              this.foundAddresses.push(item);
            } else {
              if (
                item.letter != null &&
                item.letter.toUpperCase() ==
                  this.form.address.addition.toUpperCase()
              ) {
                let address = item.street + " " + item.number;
                address = address + item.letter;
                address = address + ", " + item.postcode + " " + item.city;
                item.text = address;
                this.form.address.streetDisplay = item.street;
                this.form.address.streetDisplayState = true;
                this.form.address.city = item.city;
                this.form.address.cityState = true;
                this.form.address.street = item.street;
                this.foundAddress = address;
                this.validatePostcode();
                return;
              }
            }
          }, this);
          if (
            this.foundAddresses.length == 0 &&
            this.foundAddress.length == 0
          ) {
            this.form.address.streetDisplay = "";
            this.form.address.streetDisplayState = null;
            this.form.address.city = "";
            this.form.address.cityState = null;
            this.foundAddress = this.$t("postcode_not_found");
          }
        }
      } else {
        if (this.form.address.postcode == "") {
          this.form.address.postcodeState = false;
          this.form.address.postcodeError = this.$t("postcode_required");
        } else {
          this.form.address.postcodeState = true;
        }
      }
    },
    validatePostcode() {
      if (this.form.address.postcode == "") {
        this.form.address.postcodeState = false;
        this.form.address.postcodeError = this.$t("postcode_required");
      } else {
        this.form.address.postcodeState = true;
      }
    },
    async validateAddress() {
      let isOk = true;
      if (this.isCompany == true) {
        if (this.form.company == "") {
          isOk = false;
        }
        /*
        if (this.form.taxvat == "") {
          isOk = false;
        }
        */
      }
      if (this.form.firstname == "") {
        isOk = false;
      }
      if (this.form.lastname == "") {
        isOk = false;
      }
      if (this.form.address.country_code == "") {
        isOk = false;
      }
      if (this.form.address.postcode == "") {
        isOk = false;
      }
      if (this.form.address.streetDisplay == "") {
        isOk = false;
      }
      if (this.form.address.house_number == "") {
        isOk = false;
      }
      if (this.form.address.city == "") {
        isOk = false;
      }
      if (this.form.address.telephone == "") {
        isOk = false;
      }

      if (isOk == true) {
        const address = {
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          postcode: this.form.address.postcode,
          city: this.form.address.city,
          telephone: this.form.address.telephone,
          street: `${this.form.address.streetDisplay} ${this.form.address.house_number}${this.form.address.addition}`,
          country_code: this.form.address.country_code,
        };
        if (this.isCompany == true) {
          address.company = this.form.company;
          if (this.form.taxvat != "") {
            address.vat_id = this.form.taxvat;
          }
        }
        await this.$store.dispatch("cart/addShippingAddress", {
          address: address,
          reloadCart: false,
        });
        if (this.form.address.default_billing == true) {
          await this.$store.dispatch("cart/addBillingAddress", {
            address: address,
            reloadCart: true,
          });
        }
      }
    },
    async validateInvoiceAddress() {
      let isOk = true;
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.company == "") {
          isOk = false;
        }
        /*
        if (this.form.invoice.taxvat == "") {
          isOk = false;
        }
        */
      }
      if (this.form.invoice.firstname == "") {
        isOk = false;
      }
      if (this.form.invoice.lastname == "") {
        isOk = false;
      }
      if (this.form.invoice.address.country_code == "") {
        isOk = false;
      }
      if (this.form.invoice.address.postcode == "") {
        isOk = false;
      }
      if (this.form.invoice.address.streetDisplay == "") {
        isOk = false;
      }
      if (this.form.invoice.address.house_number == "") {
        isOk = false;
      }
      if (this.form.invoice.address.city == "") {
        isOk = false;
      }
      if (this.form.invoice.address.telephone == "") {
        isOk = false;
      }

      if (isOk == true) {
        var address = {
          company: this.form.invoice.company,
          firstname: this.form.invoice.firstname,
          lastname: this.form.invoice.lastname,
          postcode: this.form.invoice.address.postcode,
          city: this.form.invoice.address.city,
          telephone: this.form.invoice.address.telephone,
          street: `${this.form.invoice.address.streetDisplay} ${this.form.invoice.address.house_number}${this.form.invoice.address.addition}`,
          country_code: this.form.invoice.address.country_code,
        };
        if (this.form.invoice.isCompany == true) {
          address.company = this.form.invoice.company;
          if (this.form.invoice.taxvat != "") {
            address.vat_id = this.form.invoice.taxvat;
          }
        }

        this.$store.dispatch("cart/addBillingAddress", {
          address: address,
          reloadCart: true,
        });
      }
    },
    async validateInvoiceVAT() {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.taxvat != "") {
          if (
            this.form.invoice.taxvat.substring(0, 2).toUpperCase() ===
            this.form.invoice.address.country_code
          ) {
            const retval = await validateVatNumber(this.form.invoice.taxvat);
            if (retval.valid == "true") {
              this.isValidInvoiceVatNumber = true;
              this.form.invoice.taxvatState = true;
            } else {
              this.isValidInvoiceVatNumber = false;
              this.form.invoice.taxvatState = false;
              this.form.invoice.taxvatError = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidInvoiceVatNumber = false;
            this.form.invoice.taxvatState = false;
            this.form.invoice.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      } else {
        this.form.invoice.taxvatState = null;
      }
    },
    validateInvoiceCompany() {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.company == "") {
          this.form.invoice.companyState = false;
          this.form.invoice.companyError = this.$t("company_name_required");
        } else {
          this.form.invoice.companyState = true;
        }
      } else {
        this.form.invoice.companyState = null;
      }
    },
    validateInvoiceFirstname() {
      if (this.form.invoice.firstname == "") {
        this.form.invoice.firstnameState = false;
        this.form.invoice.firstnameError = this.$t("firstname_required");
      } else {
        this.form.invoice.firstnameState = true;
      }
    },
    validateInvoiceLastname() {
      if (this.form.invoice.lastname == "") {
        this.form.invoice.lastnameState = false;
        this.form.invoice.lastnameError = this.$t("lastname_required");
      } else {
        this.form.invoice.lastnameState = true;
      }
    },
    validateInvoiceCOCNumber() {
      if (this.isInvoiceCompany == true) {
        // if (this.form.invoice.coc_number == "") {
        //   this.form.invoice.coc_numberState = false;
        //   this.form.invoice.coc_numberError = this.$t("coc_number_required");
        // } else {
        //   this.form.invoice.coc_numberState = true;
        // }
      } else {
        this.form.invoice.coc_numberState = null;
      }
    },
    validateInvoiceStreet() {
      if (this.form.invoice.address.streetDisplay == "") {
        this.form.invoice.address.streetDisplayState = false;
        this.form.invoice.address.streetDisplayError =
          this.$t("street_required");
      } else {
        this.form.invoice.address.streetDisplayState = true;
      }
    },
    validateInvoiceHouseNumber() {
      if (this.form.invoice.address.house_number == "") {
        this.form.invoice.address.house_numberState = false;
        this.form.invoice.address.house_numberError = this.$t(
          "house_number_required"
        );
      } else {
        this.form.invoice.address.house_numberState = true;
      }
    },
    validateInvoiceCity() {
      if (this.form.invoice.address.city == "") {
        this.form.invoice.address.cityState = false;
        this.form.invoice.address.cityError = this.$t("city_required");
      } else {
        this.form.invoice.address.cityState = true;
      }
    },
    validateInvoicePhone() {
      if (
        this.form.invoice.address.telephone.substring(0, 3) == "+31" ||
        (this.form.invoice.address.country_code == "NL" &&
          this.form.invoice.address.telephone.substring(0, 1) !== "+")
      ) {
        if (
          this.form.invoice.address.telephone.match(this.regExNlPhone) == null
        ) {
          this.form.invoice.address.telephoneState = false;
          this.form.invoice.address.telephoneError = this.$t(
            "phone_nl_regex_test"
          );
        } else {
          this.form.invoice.address.telephoneState = true;
        }
      } else if (
        this.form.invoice.address.telephone.substring(0, 3) == "+32" ||
        (this.form.invoice.address.country_code == "BE" &&
          this.form.invoice.address.telephone.substring(0, 1) !== "+")
      ) {
        if (
          !this.form.invoice.address.telephone.match(this.regExBePhone) == null
        ) {
          this.form.invoice.address.telephoneState = false;
          this.form.invoice.address.telephoneError = this.$t(
            "phone_be_regex_test"
          );
        } else {
          this.form.invoice.address.telephoneState = true;
        }
      } else if (
        this.form.invoice.address.telephone.substring(0, 3) == "+49" ||
        (this.form.invoice.address.country_code == "DE" &&
          this.form.invoice.address.telephone.substring(0, 1) !== "+")
      ) {
        if (
          !this.form.invoice.address.telephone.match(this.regExDePhone) == null
        ) {
          this.form.invoice.address.telephoneState = false;
          this.form.invoice.address.telephoneError = this.$t(
            "phone_de_regex_test"
          );
        } else {
          this.form.invoice.address.telephoneState = true;
        }
      } else if (
        this.form.invoice.address.telephone.match(this.regExGenPhone) == null
      ) {
        this.form.invoice.address.telephoneState = false;
        this.form.invoice.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.invoice.address.telephoneState = true;
      }
    },
    async validateInvoicePostcodeHouseNumber() {
      const list = await validatePostcode(
        this.form.invoice.address.postcode,
        this.form.invoice.address.house_number
      );
      if (list.length == 1) {
        let address = list[0].street + " " + list[0].number;
        this.form.invoice.address.streetDisplay = list[0].street;
        if (list[0].letter != null) {
          this.form.invoice.address.addition = list[0].letter;
          address = address + list[0].letter;
        } else {
          this.form.invoice.address.addition = "";
        }

        address = address + ", " + list[0].postcode + " " + list[0].city;

        this.form.invoice.address.city = list[0].city;
        this.form.invoice.address.cityState = true;
        this.foundInvoiceAddress = address;
        this.validatePostcode();
      } else if (list.length == 0) {
        this.foundInvoiceAddress = this.$t("postcode_not_found");
      } else {
        list.forEach(function (item) {
          if (this.form.invoice.address.addition == "") {
            let address = item.street + " " + item.number;
            if (item.letter != null) {
              address = address + item.letter;
            }
            address = address + ", " + item.postcode + " " + item.city;
            item.text = address;
            this.foundInvoiceAddresses.push(item);
          } else {
            if (
              item.letter != null &&
              item.letter.toUpperCase() ==
                this.form.address.addition.toUpperCase()
            ) {
              let address = item.street + " " + item.number;
              address = address + item.letter;
              address = address + ", " + item.postcode + " " + item.city;
              item.text = address;
              this.form.invoice.address.streetDisplay = item.street;
              this.form.invoice.address.city = item.city;
              this.form.invoice.address.cityState = true;
              this.form.invoice.address.street = item.street;
              this.foundInvoiceAddress = address;
              this.validateInvoicePostcode();
              return;
            }
          }
        }, this);
        if (
          this.foundInvoiceAddresses.length == 0 &&
          this.foundInvoiceAddress.length == 0
        ) {
          this.form.invoice.address.streetDisplay = "";
          this.form.invoice.address.streetDisplayState = null;
          this.form.invoice.address.city = "";
          this.form.invoice.address.cityState = null;
          this.foundInvoiceAddress = this.$t("postcode_not_found");
        }
      }
    },
    validateInvoicePostcode() {
      const nl = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      const de = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/g;
      const be = /^[1-9][0-9]{3}$/g;
      if (
        this.form.invoice.address.country_code == "NL" &&
        nl.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
      } else if (
        this.form.invoice.address.country_code == "BE" &&
        be.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
      } else if (
        this.form.invoice.address.country_code == "DE" &&
        de.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
      } else {
        this.form.invoice.address.postcodeState = false;
        this.form.invoice.address.postcodeError = this.$t("postcode_required");
      }
    },
    toggleShowPass() {
      this.form.passwordFieldType =
        this.form.passwordFieldType === "password" ? "text" : "password";
    },
    confirmPasswordToggleShowPass() {
      this.form.confirmPasswordFieldType =
        this.form.confirmPasswordFieldType === "password" ? "text" : "password";
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form-box {
  border: none !important;
}
</style>